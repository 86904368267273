<template>
  <ion-page>
    <ion-content>
      <div class="container" id="container">
        <iframe width="100%" height="3000px" :src="link"></iframe>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";

export default {
  name: "PrivacyPolicy",
  components: {
    IonPage,
    IonContent,
  },
  data: () => {
    return {
      link: 'https://www.privacypolicies.com/live/153fd753-9b09-422d-97d6-4b262240a217'
    }
  }
};
</script>
<style scoped lang="scss">
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
}
</style>
